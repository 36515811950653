<template></template>
<script>
export default {
  data() {
    return {}
  },
  async mounted() {
    const response = await this.$http_client.post('/api/system/decrypt', {
      encrypted_string: this.$route.query.token
    });
    localStorage.setItem("from_admin_area", 1)
    localStorage.setItem('access_token', JSON.stringify(response.data.decrypted_string))
    await this.$store.dispatch('auth/authorizeFromLocalJWT', true);

    await this.$router.push({name: 'dashboard'})
  }
}

</script>